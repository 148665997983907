<template>
  <div class="main-content d-flex flex-column">
    <div class="header">
      <b-alert variant="info" :show="message != null">{{message}}</b-alert>
      <b-alert variant="danger" :show="error != null">{{error}}</b-alert>
    </div>
    <b-container fluid>
      <!-- Page Heading -->
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800"></h1>
      </div>
      <b-row>
        <b-col cols="12" md="6">
          <b-card data-toggle="list" header="ステータス表示" class="shadow mb-12">
            <div>
              <b-list-group>
                <b-list-group-item>
                  <b-btn variant="link" @click="$router.push('/home/users')">参加者一覧</b-btn>
                </b-list-group-item>
                <b-list-group-item>
                  <b-btn variant="link" @click="$router.push('/home/enquetes')">事前アンケート</b-btn>
                </b-list-group-item>
                <b-list-group-item>
                  <b-btn variant="link" @click="$router.push('/home/enquetes')">測定アンケート1</b-btn>
                </b-list-group-item>
                <b-list-group-item>
                  <b-btn variant="link" @click="$router.push('/home/enquetes')">測定アンケート2</b-btn>
                </b-list-group-item>
                <b-list-group-item>
                  <b-btn variant="link" @click="$router.push('/home/enquetes')">事後アンケート</b-btn>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" md="6">
          <b-card data-toggle="list" header="その他" class="shadow mb-6">
            <div>
              <b-list-group>
                <b-list-group-item>
                  <b-btn variant="link" @click="$router.push('/home/cognito_admins')">管理者リスト</b-btn>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data () {
    return {
      loading: false,
      message: null,
      error: null
    }
  },
  components: {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
